import { calculateGdd, baskervilleEmin } from "../../utils/helpers"
import { format } from "date-fns"

export default function appleIrrigationLogic(
  data,
  dateOfInterest,
  modelData,
  userGreenTipDate
) {
  const { base, elements } = modelData
  const { userInputs } = elements

  const dailyDataWithGdd = calculateGdd(
    data.dailyData,
    base,
    0,
    baskervilleEmin
  ).map((d) => {
    return { ...d, dateDisplay: format(d.date, "yyyy-MM-dd") }
  })
  // console.log(dailyDataWithGdd)
  ////////////////////////////////////////////////////////////////////////////////////

  // User Biofix1 dates -------------------------------------------------------
  let userGreenTipDateIndex = 0
  if (userGreenTipDate !== null) {
    userGreenTipDateIndex = dailyDataWithGdd.findIndex(
      (day) => day.dateDisplay === userGreenTipDate
    )
  }
  ////////////////////////////////////////////////////////////////////////////////////

  // Recalculating degree-days when biofix1 ----------------------------------
  let gdd1 = 0
  let indices1 = []
  const mData = dailyDataWithGdd.map((day, i) => {
    let p = {
      date: day.date,
      dateDisplay: day.dateDisplay,
      ms: day.ms,
      dayOfYear: day.dayOfYear,
      isForecast: day.isForecast,
      dd: day.dd,
      gdd: day.gdd,
      greenTipGdd: null,
    }

    // Recalculate if user has selected a biofix1 date
    if (userGreenTipDate !== null) {
      if (i >= userGreenTipDateIndex) {
        indices1.push(i)
        gdd1 += day.dd !== "N/A" ? day.dd : 0
        p.greenTipGdd = Math.round(gdd1)
      }
    } else {
      if (day.gdd >= userInputs.greenTipDate.biofix) {
        indices1.push(i)
        gdd1 += day.dd !== "N/A" ? day.dd : 0
        p.greenTipGdd = Math.round(gdd1)
      }
    }
    return p
  })
  // console.log(mData)
  ////////////////////////////////////////////////////////////////////////////////////

  //  Green Tip Date ----------------------------------------------------------
  let greenTipDate = null
  if (indices1.length !== 0) {
    const dd = mData[indices1[0]]
    if (dd.dayOfYear <= dateOfInterest.dayOfYear) {
      greenTipDate = dd
    }
  }
  ////////////////////////////////////////////////////////////////////////////////////

  let selectedDate = mData.find(
    (day) => day.dayOfYear === dateOfInterest.dayOfYear
  )
  if (!selectedDate) {
    selectedDate = mData.find((d) => d.isForecast)
  }

  ////////////////////////////////////////////////////////////////////////////////////
  return {
    selectedDate,
    greenTipDate,
  }
}
