import GlobalStateContext from '../../context/globalStateContext'
import {useAuth0} from '../../context/react-auth0-spa'
import React from 'react'
import Select from 'react-select'
import {useSnackbar} from 'react-simple-snackbar'

const customStylesDropdown = {
  option: provided => ({
    ...provided,
    textAlign: 'left',
  }),
  menu: provided => ({
    ...provided,
    borderRadius: 6,
  }),
  input: provided => ({
    ...provided,
    width: '100%',
    textAlign: 'left',
  }),
}

export default function Dropdown({
  modelId,
  width,
  id,
  list,
  ariaLabel,
  setUserItem,
  item,
  dbKey,
  station,
}) {
  const {dateOfInterest} = React.useContext(GlobalStateContext)
  const {user, setUser} = useAuth0()
  const [openSnackbar] = useSnackbar()

  function handleInputChange(d) {
    if (user) {
      let model = user.activeTools.find(model => model.id === modelId)
      if (model) {
        const activeToolsUpdated = user.activeTools.filter(
          model => model.id !== modelId,
        )
        if (Object.keys(model[dbKey]).includes(`${dateOfInterest.year}`)) {
          model[dbKey][dateOfInterest.year][station?.id] = d
        } else {
          model[dbKey][dateOfInterest.year] = {
            [station.id]: d,
          }
        }
        setUser({
          ...user,
          activeTools: [...activeToolsUpdated, model],
        })
        openSnackbar('Database has been updated!')
      }
    }
    setUserItem(d)
  }

  return (
    <div className={`${width}`}>
      <Select
        name={id}
        id={id}
        aria-label={ariaLabel}
        value={item}
        onChange={handleInputChange}
        options={list}
        styles={customStylesDropdown}
        theme={theme => ({
          ...theme,
          borderRadius: 6,
          colors: {
            ...theme.colors,
            primary25: '#edf2f7',
            primary50: '#86BFDF',
            primary: '#316795',
          },
        })}
      ></Select>
    </div>
  )
}
