import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
      Robinson, T.L., Lakso, A.N., Lordan, J., Francescatto, P., Dragoni, D., DeGaetano, A.T., and Eggleston, K. 2017. Precision irrigation management of apple with an apple-specific Penman-Monteith Model. Acta Hortic. 1150 pp 245-250.
      </p>
    </>
  )
}
