import React from "react"

export default function MoreInfo() {
  return (
    <ul className="px-4 mt-6 text-sm text-left list-disc">
      <li className="mb-4">
        <a
          href="https://live-cu-newa.pantheonsite.io/wp-content/uploads/apple-irrigation-more-info-20210319.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          About the Cornell Apple Carbohydrate Thinning Model
        </a>
      </li>
    </ul>
  )
}
