import React from "react"

export default function Acknowledgements() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
      The Cornell apple irrigation model was authored by Terence Robinson, Alan Lakso and D. Dragoni, School of Integrated Plant Science, Horticulture Section, Cornell University.
      </p>
    </>
  )
}
