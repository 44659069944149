import axios from "axios";
import { format, getDayOfYear } from "date-fns";
import React from "react";
import { useQuery } from "react-query";
import { useSnackbar } from "react-simple-snackbar";
import HashLoader from "react-spinners/HashLoader";
import BiofixDateInput from "../../components/models/biofixDateInput";
import ModelLayout from "../../components/models/modelLayout";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./apple-irrigation.json";
import Acknowledgments from "./_acknowledgements";
import Dropdown from "./_dropdown";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsTable from "./_resultsTable";

const visibleElements = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

async function runModel(sid, dateOfInterest, greenTip) {
  // console.log("Fetch Apple Irrigation Data...")
  return await axios
    .get(
      `${window.location.protocol}//newa.nrcc.cornell.edu/newaTools/process_input_new?type=apple_et&stn=${sid}&accend=${dateOfInterest}&greentip=${greenTip}&&output=json`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(`Failed to load data`, err));
}

export default function AppleIrrigation() {
  const h1Text = modelData.title;
  const { seasonStart, seasonEnd, elements, title, id: modelId } = modelData;
  const dropdownItems = elements.userInputs.ageOfOrchard;
  const { user, setUser, isAuthenticated } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const { dateOfInterest } = React.useContext(GlobalStateContext);
  const isModelInSeason = modelInSeason(dateOfInterest, modelData);
  const [openSnackbar] = useSnackbar();

  const { sdate, edate } = sdateEdate(dateOfInterest, seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);

  const [userGreenTipDate, setUserGreenTipDate] = React.useState(null);
  const [inRowSpacing, setInRowSpacing] = React.useState("");
  const [betweenRowSpacing, setBetweenRowSpacing] = React.useState("");
  const [treesPerAcre, setTreesPerAcre] = React.useState("");
  const [ageOfOrchard, setAgeOfOrchard] = React.useState(dropdownItems[4]);
  const [rainfallAndIrrigation, setRainfallAndIrrigation] = React.useState([]);
  const [makeAppleQuery, setMakeAppleQuery] = React.useState(false);
  const [canRunModel, setCanRunModel] = React.useState(false);

  function determineTableContent() {
    if (makeAppleQuery) {
      setCanRunModel(true);

      if (user && treesPerAcre.length !== 0 && treesPerAcre > 0) {
        let model = user.activeTools.find((model) => model.id === modelId);
        if (model) {
          const activeToolsUpdated = user.activeTools.filter(
            (model) => model.id !== modelId
          );
          if (
            Object.keys(model["treesPerAcre"]).includes(
              `${dateOfInterest.year}`
            )
          ) {
            model["treesPerAcre"][dateOfInterest.year][station.id] =
              treesPerAcre;
          } else {
            model["treesPerAcre"][dateOfInterest.year] = {
              [station.id]: treesPerAcre,
            };
          }

          setUser({
            ...user,
            activeTools: [...activeToolsUpdated, model],
          });
        }
        openSnackbar("Database has been updated!");
      }
    }
  }

  function handleInputChange(e, fn) {
    if (e.target.value.length === 0) {
      fn("");
    } else {
      if (isNaN(+e.target.value)) {
        fn("");
      } else {
        if (+e.target.value > 0) {
          fn(+e.target.value);
        }
      }
    }
  }

  function addRainfallAndIrrigation(row) {
    if (user) {
      const insert = {
        ...row,
        year: dateOfInterest.year,
        stationId: station.id,
      };
      let model = user.activeTools.find((model) => model.id === modelId);

      if (model) {
        const activeToolsUpdated = user.activeTools.filter(
          (model) => model.id !== modelId
        );
        let results = [];
        let modelRainfallAndIrrigation = {};
        if ("rainfallAndIrrigation" in model) {
          modelRainfallAndIrrigation = model.rainfallAndIrrigation;

          if (rainfallAndIrrigation.map((d) => d.id).includes(insert.id)) {
            results = [
              ...rainfallAndIrrigation.filter(
                (d) =>
                  d.id !== insert.id &&
                  d.stationId === station.id &&
                  d.year === dateOfInterest.year
              ),
              insert,
            ];
          } else {
            results = [...rainfallAndIrrigation, insert];
          }

          if (String(dateOfInterest.year) in modelRainfallAndIrrigation) {
            // console.log("same year")
            if (station.id in modelRainfallAndIrrigation[dateOfInterest.year]) {
              // console.log("same station")
              model.rainfallAndIrrigation[dateOfInterest.year][station.id] =
                results;
            } else {
              // console.log("different station")
              model.rainfallAndIrrigation[dateOfInterest.year][station.id] =
                results;
            }
          } else {
            // console.log("different year")
            model.rainfallAndIrrigation[dateOfInterest.year] = {
              [station.id]: results,
            };
          }
        } else {
          // console.log("first time")
          results = [insert];
          model["rainfallAndIrrigation"] = {
            [dateOfInterest.year]: {
              [station.id]: results,
            },
          };
        }
        setUser({
          ...user,
          activeTools: [...activeToolsUpdated, model],
        });
        openSnackbar("Updating Database...");
      }
    }
  }

  let mData = null;
  if (data && isModelInSeason) {
    mData = modelLogic(data, dateOfInterest, modelData, userGreenTipDate);
  }
  // console.log(mData)
  const stationId = station === null ? "" : station.id;
  const doi = format(dateOfInterest.date, "MM-dd-yyyy");
  let greenTipDate = "";
  if (userGreenTipDate) {
    greenTipDate = format(
      new Date(`${userGreenTipDate}T00:00:00`),
      "MM-dd-yyyy"
    );
  } else {
    if (mData && mData.greenTipDate) {
      greenTipDate = format(mData.greenTipDate.date, "MM-dd-yyyy");
    }
  }

  const appleQuery = useQuery(
    `${
      station && station.name
    }(${stationId}) - doi:${doi} - greenTip:${greenTipDate}`,
    () => runModel(stationId, doi, greenTipDate),
    {
      refetchOnWindowFocus: false,
      enabled: canRunModel,
      cacheTime: 900000, // data remains in memory for 15 minutes
    }
  );
  // console.log({appleQuery})

  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showManagementGuide, setShowManagementGuide] = React.useState(true);
  const [showResultsTable, setShowResultsTable] = React.useState(true);

  const isVisible = station && mData;

  React.useEffect(() => {
    setInRowSpacing("");
    setBetweenRowSpacing("");
    setTreesPerAcre("");
  }, [station]);

  React.useEffect(() => {
    const dd = `${greenTipDate.slice(6)}-${greenTipDate.slice(0, 5)}T00:00`;
    if (
      station &&
      greenTipDate &&
      dateOfInterest.dayOfYear >= getDayOfYear(new Date(`${dd}`)) &&
      treesPerAcre.length !== 0
    ) {
      // setCanRunModel(true)
      setMakeAppleQuery(true);
    } else {
      setCanRunModel(false);
      setMakeAppleQuery(false);
    }
  }, [station, greenTipDate, treesPerAcre, dateOfInterest]);

  React.useEffect(() => {
    if (inRowSpacing.length === 0) return;
    if (betweenRowSpacing.length === 0) return;

    let d1 = "";
    if (inRowSpacing.length !== 0) {
      d1 = isNaN(+inRowSpacing) ? "" : +inRowSpacing;
    }
    let d2 = "";
    if (betweenRowSpacing.length !== 0) {
      d2 = isNaN(+betweenRowSpacing) ? "" : +betweenRowSpacing;
    }

    if (typeof d1 !== "string" && typeof d2 !== "string") {
      setTreesPerAcre(Math.round(1 / (d1 * d2 * 0.000022957)));
    }
  }, [inRowSpacing, betweenRowSpacing, ageOfOrchard]);

  React.useEffect(() => {
    if (!user) return;

    if (Object.keys(user.activeTools)) {
      const model = user.activeTools.find((model) => model.id === modelId);
      if (model) {
        // Green Tip Date ---------------------------------------
        if (
          Object.keys(model.greenTip).includes(`${dateOfInterest.year}`) &&
          Object.keys(model.greenTip[dateOfInterest.year]).includes(station.id)
        ) {
          setUserGreenTipDate(model.greenTip[dateOfInterest.year][station.id]);
        } else {
          setUserGreenTipDate(null);
        }
        // Age Of Orchard ----------------------------------------
        if (
          Object.keys(model.ageOfOrchard).includes(`${dateOfInterest.year}`) &&
          Object.keys(model.ageOfOrchard[dateOfInterest.year]).includes(
            station.id
          )
        ) {
          setAgeOfOrchard(model.ageOfOrchard[dateOfInterest.year][station.id]);
        } else {
          setAgeOfOrchard(dropdownItems[4]);
        }
        // Tree Per Acre -------------------------------------------
        if (
          Object.keys(model.treesPerAcre).includes(`${dateOfInterest.year}`) &&
          Object.keys(model.treesPerAcre[dateOfInterest.year]).includes(
            station.id
          )
        ) {
          setTreesPerAcre(model.treesPerAcre[dateOfInterest.year][station.id]);
        } else {
          setTreesPerAcre("");
        }
        // Rainfall and Irrigation -------------------------------------------
        if ("rainfallAndIrrigation" in model) {
          if (
            String(dateOfInterest.year) in model.rainfallAndIrrigation &&
            station.id in model.rainfallAndIrrigation[dateOfInterest.year]
          ) {
            setRainfallAndIrrigation(
              model.rainfallAndIrrigation[dateOfInterest.year][station.id]
            );
          }
        }
      }
    }
  }, [station, modelId, user, dateOfInterest, dropdownItems]);

  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      station={station}
      stationList={user ? favoriteStations : stationList}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading || appleQuery.isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      titleSize={"text-lg lg:text-2xl"}
      moreInfo={<MoreInfo moreInfo={modelData.moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showManagementGuide={showManagementGuide}
      setShowManagementGuide={setShowManagementGuide}
      showResultsTable={showResultsTable}
      setShowResultsTable={setShowResultsTable}
      tutorialLink={modelData.tutorialLink}
      h1Text={h1Text}
    >
      {/* User Input */}
      <div className="mb-16 sm:mb-18 md:mb-20">
        {isModelInSeason && isVisible && (
          <>
            <h2 className="mb-3 font-semibold text-gray-600 sm:mb-5 md:mb-6 md:text-2xl">
              User Inputs
            </h2>
            <div className="px-6 py-6 text-center bg-white rounded-lg shadow-lg sm:text-left">
              {userGreenTipDate === null && mData && mData.greenTipDate && (
                <div className="flex items-center justify-center px-2 rounded-md bg-gray-50">
                  <p className="text-sm text-justify sm:text-left">
                    Green tip date below is estimated from growing degree day
                    accumulations. Enter your orchard's green tip date to
                    fine-tune results. Enter in-row and between-row spacing (or
                    trees/acre) and select age of orchard from menu.
                  </p>
                </div>
              )}
              <div className="grid max-w-xl grid-cols-1 gap-8 py-4 mx-auto lg:py-8 md:grid-cols-2">
                <div className="">
                  <BiofixDateInput
                    modelId={modelId}
                    id={"greenTip"}
                    dbKey="greenTip"
                    minDate={new Date(`${dateOfInterest.year}-${seasonStart}`)}
                    maxDate={dateOfInterest.date}
                    biofix={mData.greenTipDate}
                    userBiofix={userGreenTipDate}
                    setUserBiofix={setUserGreenTipDate}
                    setUserBiofix2={() => {}}
                    label={"Green tip date"}
                    modelName={title}
                    tooltip={`Reset green tip to default value`}
                    datesWithNoData={[]}
                    isDisabled={false}
                    station={station}
                  ></BiofixDateInput>
                </div>

                <div className="">
                  <div className="mb-2 font-semibold leading-5 text-left text-gray-700">
                    Age of orchard
                  </div>
                  <Dropdown
                    modelId={modelId}
                    id="ageOfOrchard"
                    dbKey="ageOfOrchard"
                    modelName={title}
                    width="w-full"
                    ariaLabel="Age of Orchard"
                    list={dropdownItems}
                    item={ageOfOrchard}
                    setUserItem={setAgeOfOrchard}
                    station={station}
                  ></Dropdown>
                </div>

                <div className="">
                  <label
                    htmlFor="inRowSpacing"
                    className="block mb-2 font-semibold leading-5 text-left text-gray-700"
                  >
                    In row spacing (ft)
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="inRowSpacing"
                      id="inRowSpacing"
                      className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-secondary-700 focus:border-secondary-700 sm:text-sm"
                      value={inRowSpacing}
                      onChange={(e) => {
                        setCanRunModel(false);
                        handleInputChange(e, setInRowSpacing);
                      }}
                    />
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="betweenRowSpacing"
                    className="block mb-2 font-semibold leading-5 text-left text-gray-700"
                  >
                    Between row spacing (ft)
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="betweenRowSpacing"
                      id="betweenRowSpacing"
                      className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-secondary-700 focus:border-secondary-700 sm:text-sm"
                      value={betweenRowSpacing}
                      onChange={(e) => {
                        setCanRunModel(false);
                        handleInputChange(e, setBetweenRowSpacing);
                      }}
                    />
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="treesPerAcre"
                    className="block mb-2 font-semibold leading-5 text-left text-gray-700"
                  >
                    Trees per acre
                  </label>
                  <div className="flex mt-1">
                    <input
                      type="text"
                      name="treesPerAcre"
                      id="treesPerAcre"
                      className="block border-gray-300 rounded-md shadow-sm w-28 focus:ring-secondary-700 focus:border-secondary-700 sm:text-sm"
                      value={treesPerAcre}
                      onChange={(e) => {
                        setCanRunModel(false);
                        handleInputChange(e, setTreesPerAcre);
                      }}
                    />

                    {!canRunModel && (
                      <button
                        onClick={determineTableContent}
                        disabled={!makeAppleQuery}
                        type="button"
                        className={`flex items-center px-3 ml-2 text-sm font-medium leading-4 text-white bg-orange-600 border border-transparent rounded-md shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 ${
                          makeAppleQuery ? `opacity-100` : `opacity-50`
                        }`}
                      >
                        {appleQuery.isLoading ? (
                          <span className="w-20">
                            <HashLoader
                              size={12}
                              color={"#fff"}
                              loading={appleQuery.isLoading}
                            />
                          </span>
                        ) : (
                          <span className="w-20">Run Model</span>
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center px-2 mt-10 rounded-md bg-gray-50">
                {userGreenTipDate === null && mData && !mData.greenTipDate && (
                  <p className="text-sm text-justify sm:text-left">
                    Report cannot be generated before green tip. If green tip
                    has occurred, enter the date of occurrence above.
                  </p>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      {/* Results Table */}
      {isModelInSeason &&
        showResultsTable &&
        makeAppleQuery &&
        appleQuery.data && (
          <div className="mb-16 sm:mb-18 md:mb-20">
            <ResultsTable
              isAuthenticated={isAuthenticated}
              station={station}
              modelData={modelData}
              tableData={appleQuery.data.data}
              ageOfOrchard={ageOfOrchard}
              treesPerAcre={treesPerAcre}
              rainfallAndIrrigation={rainfallAndIrrigation}
              addRainfallAndIrrigation={addRainfallAndIrrigation}
            ></ResultsTable>
          </div>
        )}
    </ModelLayout>
  );
}
