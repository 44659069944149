import {format, getDayOfYear} from 'date-fns'
import React from 'react'
import {CSVLink} from 'react-csv'
import GlobalStateContext from '../../context/globalStateContext'

const th = `px-1 py-2 text-xs lg:text-sm leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800 sticky top-0  shadow-sm`
const td = `text-xs lg:text-sm px-3 py-2 border border-gray-200 leading-6 text-gray-700 whitespace-nowrap bg-white`

const modelTreeDensity = 518
const gallonsPerLiter = 0.264172052
const gallonsPerInch = 27154

// Create an editable cell renderer
const EditableCell = ({
  setIsEditRow,
  value: initialValue,
  row: {index},
  column: {param},
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, param, value)
    setIsEditRow(-1)
  }

  const handleKeyPress = e => {
    if (e.keyCode === 13) {
      e.target.blur()
    }
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <input
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={handleKeyPress}
      type="text"
      name={param}
      id={param}
      className="w-full py-0 text-center border-orange-400 rounded-md shadow-sm focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm"
    />
  )
}

export default function ResultsTable({
  isAuthenticated,
  station,
  modelData,
  tableData,
  ageOfOrchard,
  treesPerAcre,
  rainfallAndIrrigation,
  addRainfallAndIrrigation,
}) {
  const {dateOfInterest} = React.useContext(GlobalStateContext)
  const filteredRainfallAndIrrigation = rainfallAndIrrigation.filter(
    d => d.stationId === station.id && d.year === dateOfInterest.year,
  )

  const tableDataMixed = tableData.map((d, i) => {
    let p = {}
    p.id = i
    p.date = d[0]
    p.et = d[1]
    p.rainInches = d[2]
    filteredRainfallAndIrrigation.forEach(d => {
      if (d.date === p.date) {
        p.userRainInches = d.userRainInches
        p.userIrrigPerAcre = d.userIrrigPerAcre
      }
    })
    return p
  })

  const [isEditRow, setIsEditRow] = React.useState(-1)
  const {resultsTable} = modelData.elements

  let cumulativeWB = 0
  const ciccio = tableDataMixed.map(d => {
    const gallonsPerTree =
      d.et *
      (1 / treesPerAcre) *
      modelTreeDensity *
      ageOfOrchard.scaleEt *
      gallonsPerLiter

    let rainInches = null
    if (d.userRainInches) {
      rainInches = d.userRainInches
    } else {
      rainInches = d.rainInches
    }

    let irrigPerAcre = null
    if (d.userIrrigPerAcre) {
      irrigPerAcre = d.userIrrigPerAcre
    } else {
      irrigPerAcre = isNaN(+d.irrigPerAcre) ? 0 : parseInt(d.irrigPerAcre)
    }

    const D2 = rainInches === -999 ? 0 : rainInches

    const rainPerAcre = D2 * gallonsPerInch * ageOfOrchard.scaleRain
    const gallonsPerAcre = gallonsPerTree * treesPerAcre
    let dailyWB = rainPerAcre + irrigPerAcre - gallonsPerAcre
    cumulativeWB = cumulativeWB + dailyWB > 0 ? 0 : cumulativeWB + dailyWB

    return {
      date: d.date,
      doy: getDayOfYear(new Date(`${d.date}T23:00`)),
      displayDate: format(new Date(`${d.date}T00:00`), 'MMM d'),
      gallonsPerTree: gallonsPerTree.toFixed(1),
      gallonsPerAcre: gallonsPerAcre.toFixed(0),
      rainInches: rainInches <= -999 ? '-' : rainInches,
      rainPerAcre:
        rainInches <= -999
          ? '-'
          : (rainInches * gallonsPerInch * ageOfOrchard.scaleRain).toFixed(0),
      irrigPerAcre,
      dailyWaterBalance: dailyWB.toFixed(0),
      cumulativeWaterBalance: cumulativeWB.toFixed(0),
      userRainInches: d.userRainInches,
      userIrrigPerAcre: d.userIrrigPerAcre,
    }
  })

  const updateMyData = (rowIndex, param, value) => {
    // console.log(rowIndex, param, value)
    let tableDataCopy = [...ciccio]
    const d = isNaN(+value) ? 0 : +value
    // console.log(d)
    let row = {
      id: rowIndex,
      date: tableDataCopy[rowIndex].date,
      rainInches: tableDataCopy[rowIndex].rainInches,
      irrigPerAcre: tableDataCopy[rowIndex].irrigPerAcre,
      userRainInches: tableDataCopy[rowIndex].userRainInches,
      userIrrigPerAcre: tableDataCopy[rowIndex].userIrrigPerAcre,
    }

    if (param === 'rainInches') {
      row['userRainInches'] = d
    }
    if (param === 'irrigPerAcre') {
      row['userIrrigPerAcre'] = d
    }

    // console.log(row)
    addRainfallAndIrrigation(row)
  }

  function handleEditRow(idx) {
    const id = idx === isEditRow ? null : idx
    setIsEditRow(id)
  }

  // console.log(userDataTable)
  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-xl font-semibold text-gray-600 md:text-2xl">
          {resultsTable.title}
        </h2>

        <div className="flex justify-center rounded-md shadow-sm">
          <button
            type="button"
            aria-label="download results table data in csv format"
            className="inline-flex items-center p-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md lg:px-3 lg:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>

            <CSVLink
              className="text-white no-underline"
              filename={`${modelData.title} Results Table.csv`}
              data={ciccio}
            >
              <span className="hidden text-white lg:inline-block">
                Download
              </span>{' '}
              <span className="text-white">CSV</span>
            </CSVLink>
          </button>
        </div>
      </div>

      <div className="flex flex-col my-4 lg:flex-row lg:justify-between lg:items-center">
        <div className="flex items-center">
          <span className="inline-block py-2 mr-4">
            <a
              className="text-xs lg:text-sm"
              href={`http://forecast.weather.gov/MapClick.php?textField1=${station.lat}&textField2=${station.lon}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Forecast Details
            </a>
          </span>
        </div>

        {filteredRainfallAndIrrigation.length !== 0 && (
          <div className="flex mt-2 text-sm lg:mt-0 lg:items-center lg:flex-row">
            <span className="mb-2 font-semibold lg:mb-0 lg:mr-2">
              Modified Cells:
            </span>
            <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm bg-yellow-50 text-yellow-700 font-semibold">
              User Data
            </span>
          </div>
        )}

        <div className="flex mt-2 text-sm lg:mt-0 lg:items-center lg:flex-row">
          <span className="mb-2 font-semibold lg:mb-0 lg:mr-2">
            Recommendation:
          </span>
          <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm bg-purple-50 text-purple-700 font-semibold">
            Irrigate
          </span>
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <div className="py-2 -my-2 overflow-x-auto lg:-mx-6 lg:px-6 xl:-mx-8 xl:px-8">
          <div className="inline-block min-w-full overflow-y-auto align-middle border-b border-gray-200 shadow lg:rounded-lg">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  {isAuthenticated && (
                    <th className={th} rowSpan="2">
                      Edit Values
                    </th>
                  )}
                  <th className={th} rowSpan="2">
                    Date <small>({dateOfInterest.year})</small>
                  </th>
                  <th className={th} colSpan="2">
                    Orchard ET (gallons)
                  </th>
                  <th className={th} colSpan="2">
                    Rainfall
                  </th>
                  <th className={th}>Irrigation</th>
                  <th className={th} colSpan="2">
                    Water Balance (gallons/acre)
                  </th>
                </tr>
                <tr>
                  <th className={`${th} top-9`}>per tree</th>
                  <th className={`${th} top-9`}>per acre</th>
                  <th className={`${th} top-9`}>inches</th>
                  <th className={`${th} top-9`}>gallons/acre</th>
                  <th className={`${th} top-9`}>gallons/acre</th>
                  <th className={`${th} top-9`}>Daily</th>
                  <th className={`${th} top-9`}>Cumulative</th>
                </tr>
              </thead>
              <tbody>
                {ciccio.map((d, i) => {
                  let selDay = ''
                  if (d.doy === dateOfInterest.dayOfYear) {
                    selDay = 'font-extrabold'
                  }
                  const checked = isEditRow === i
                  const isEven = i % 2 === 0 ? undefined : 'bg-gray-50'
                  const recommendedIrrigation =
                    d.cumulativeWaterBalance < -10000
                      ? 'bg-purple-50 text-purple-700'
                      : undefined
                  return (
                    <tr
                      key={d.date}
                      className={`text-center ${selDay} ${isEven}`}
                    >
                      {isAuthenticated && (
                        <td className={`${td}${isEven}`}>
                          <input
                            id={i}
                            name="editRow"
                            type="checkbox"
                            className="w-4 h-4 text-orange-600 border-gray-300 rounded form focus:ring-orange-500"
                            checked={checked}
                            onChange={() => handleEditRow(i)}
                          />
                        </td>
                      )}
                      <td className={`${td}${isEven}`}>
                        <span className="flex flex-col items-center">
                          {d.displayDate}
                          <small className="font-bold text-gray-500">
                            {dateOfInterest.isCurrentYear &&
                            d.doy > getDayOfYear(new Date())
                              ? 'Forecast'
                              : ''}
                          </small>
                        </span>
                      </td>
                      <td className={`${td}${isEven}`}>{d.gallonsPerTree}</td>
                      <td className={`${td}${isEven}`}>{d.gallonsPerAcre}</td>
                      <td className={`${td}${isEven} w-24 py-0`}>
                        {checked ? (
                          <EditableCell
                            setIsEditRow={setIsEditRow}
                            value={d.rainInches}
                            row={{index: i}}
                            column={{param: 'rainInches'}}
                            updateMyData={updateMyData}
                          ></EditableCell>
                        ) : (
                          <>
                            <span
                              className={`${
                                d.userRainInches
                                  ? `bg-yellow-50 px-2 py-1 w-3 rounded-md text-yellow-700`
                                  : ``
                              }`}
                            >
                              {d.rainInches}
                            </span>
                          </>
                        )}
                      </td>
                      <td className={`${td}${isEven}`}>{d.rainPerAcre}</td>
                      <td className={`${td}${isEven} w-24 py-0`}>
                        {checked ? (
                          <EditableCell
                            setIsEditRow={setIsEditRow}
                            value={d.irrigPerAcre}
                            row={{index: i}}
                            column={{param: 'irrigPerAcre'}}
                            updateMyData={updateMyData}
                          ></EditableCell>
                        ) : (
                          <span
                            className={`${
                              d.userIrrigPerAcre
                                ? `bg-yellow-50 px-2 py-1 w-3 rounded-md text-yellow-700`
                                : ``
                            }`}
                          >
                            {d.irrigPerAcre}
                          </span>
                        )}
                      </td>
                      <td className={`${td}${isEven}`}>
                        {d.dailyWaterBalance}
                      </td>
                      <td className={`${td}${isEven} ${recommendedIrrigation}`}>
                        {d.cumulativeWaterBalance}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
